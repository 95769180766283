<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">IT Specialist
                                <router-link to="/careers/IT-specialist/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>The Role</h4>
                                <p>We are looking for skilled <b>IT Specialists</b> who have professional experiences and strong technical knowledge of Mac and Windows OS and other relevant software. The person will be providing general IT and desktop support, system access and application support, and asset and endpoint management.</p>
                                <p>We know it can be tricky to apply for roles, wondering if the position is right for you and if you and your experience are suitable for the role. Many people won't apply for roles unless they feel that they tick every single box. At eFlex, we look for many different skills and abilities, and we're always looking for how new team members can add to eFlex and our culture. So if you don't think you quite meet all of the skills listed, we'd still love to hear from you!</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll be doing
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>General IT and Desktop Support</li>
                                            <ul class="iconlist iconlist-color mb-0">
                                                <li><i class="icon-plus-sign"></i>Provide support to employees across all locations via helpdesk, email and chat</li>
                                                <li><i class="icon-plus-sign"></i>Research, diagnose, troubleshoot, and identify solutions to resolve system issues across all Mac & Windows-based computers, mobile devices, and other technology products/applications used</li>
                                                <li><i class="icon-plus-sign"></i>Diagnose and provide basic network and VPN support</li>
                                            </ul>
                                            <li><i class="icon-ok"></i>System Access and Application Support</li>
                                            <ul class="iconlist iconlist-color mb-0">
                                                <li><i class="icon-plus-sign"></i>Create and/or maintain user access, accounts, passwords, data integrity and security across various systems and applications</li>
                                                <li><i class="icon-plus-sign"></i>Ensure leaving employees are offboarded from all systems and applications in a timely manner</li>
                                                <li><i class="icon-plus-sign"></i>Perform audits of applications to ensure access-controls are properly implemented</li>
                                            </ul>
                                            <li><i class="icon-ok"></i>Asset and Endpoint Management</li>
                                            <ul class="iconlist iconlist-color mb-0">
                                                <li><i class="icon-plus-sign"></i>Install and configure computer systems and applications within the company</li>
                                                <li><i class="icon-plus-sign"></i>Work with third-party partners to repair hardware issues</li>
                                                <li><i class="icon-plus-sign"></i>Support endpoint security standards (2FA, antivirus, firewall, etc.)</li>
                                            </ul>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Minimum Requirements
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>2-3 years of professional experience in Information Technology</li>
                                            <li><i class="icon-ok"></i>Strong technical knowledge of Mac and Windows operating systems and other relevant software</li>
                                            <li><i class="icon-ok"></i>Knowledge of networking fundamentals and support experience (connectivity, VPN, printing, etc.)</li>
                                            <li><i class="icon-ok"></i>Familiar with common access/authentication systems and tools (e.g. Okta, Duo)</li>
                                            <li><i class="icon-ok"></i>Familiar with Google Workspace, Zoom, Slack, MS Office, and other commonly used tools</li>
                                            <li><i class="icon-ok"></i>Strong analytical skills, communication skills, and the desire to stay up to date on the latest technology.</li>
                                            <li><i class="icon-ok"></i>Ability to work in a fast paced environment, prioritize appropriately and handle multiple tasks simultaneously.</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i>We live our values as playmakers, obsessed with learning, care personally about our colleagues and partners, are radically open-minded, and take pride in everything we do.</li>
                                            <li><i class="icon-plus-sign"></i>We offer a Competitive Salary.</li>
                                            <li><i class="icon-plus-sign"></i>Work-from-home setup as we prepare to provide a safe environment for our employees. Our Future of Work plan is to move towards a hybrid work model (in-office & from home).</li>
                                            <li><i class="icon-plus-sign"></i>We offer comprehensive HMO and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i>Recharge and go on your next getaway or simply take time off for yourself through our flexible personal & sick days. We want our team to be happy and healthy :)</li>
                                            <li><i class="icon-plus-sign"></i>We support our employee's career growth and development by offering opportunities for promotion.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-title">
                                            About eFlexervices
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>eFlexervices is a 23-year-old BPO company providing exceptional quality and unwavering trust. Our partnership approach to the business allows us to match the right talent to every organization we support. We invest in people to optimize performance and maximize efficiency. We work hard to produce the highest possible results for our partners.</p>
                                        <p>Performance is the underlying foundation that drives eFlexervices. We deliver the metrics our partners expect through proper recruitment and heavy investment in the right people.</p>
                                        <p>Deep integration within our partners' organizations drives our team to take ownership of their work. This stakeholder mindset pushes higher performance, better quality, and longer retention.</p>
                                    </div>
                                    <router-link to="/careers/IT-specialist/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    },
    mounted() {
		window.scrollTo(0, 0)
	}
}
</script>